import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { environment } from '@ct/environment';
import { stripTags } from '@ct/shared/helpers';

import { EntityType } from '../enums';

const endpoint = environment.reportsApiBaseUrl;

export interface FlaggedEntity {
  readonly entityType: EntityType;
  readonly entityId: string;
  readonly reason: string;
}

@Injectable({ providedIn: 'root' })
export class ReportApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  flagAsInappropriate(flagEntity: FlaggedEntity) {
    const request = { ...flagEntity, reason: stripTags(flagEntity.reason) };
    return this.post(``, request);
  }
}
