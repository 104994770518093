<div class="buttons-wrapper">
  <ct-link [link]="editLink" *ngIf="isAuthor">
    <ct-button variant="flat" color="accent">{{ 'MARKETPLACE.EDIT_ITEM.EDIT' | translate }}</ct-button>
  </ct-link>
</div>
<div>
  <div class="row col-12 flex-column-reverse flex-md-row">
    <div class="col-12 col-md-6 mb-3">
      <ct-progressive-image
        class="image mb-3"
        *ngIf="!images?.length || images.length <= 1"
        [noImagePlaceholder]="true"
        [height]="'auto'"
        [width]="'100%'"
        [publicUrl]="publicUrl"
        [thumbnail]="thumbnail"
        [altText]="item?.title + ' featured photo'"
      >
      </ct-progressive-image>
      <ct-gallery
        *ngIf="images?.length && images.length > 1"
        [items]="images"
        [thumbPosition]="thumbnailsPosition"
        [imageDescriptionPrefix]="item?.title"
        photos
      ></ct-gallery>
    </div>
    <div class="col-12 col-md-6">
      <div class="headline mt-2">
        <h2 class="title">{{ item?.title }}</h2>
      </div>
      <div class="item__info">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.ITEM_NUMBER' | translate }}</strong> {{ item?.id }}
      </div>

      <div class="line--shadow"></div>

      <div class="item__info" *ngIf="item?.brand">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.BRAND' | translate }}</strong> {{ item.brand }}
      </div>
      <div class="item__info" *ngIf="item?.modelTitle">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.MODEL' | translate }}</strong> {{ item.modelTitle }}
      </div>
      <div class="item__info">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.CONDITION' | translate }}</strong> {{ condition }}
      </div>

      <div class="line--shadow"></div>

      <div *ngIf="!item?.amazonAffiliateUrl?.trim()">
        <div class="item__info d-flex align-content-center mb-3 mt-3">
          <strong>{{ 'MARKETPLACE.ITEM_DETAILS.PRICE' | translate }}</strong
          >&nbsp;<span class="item__price">{{ item?.price | currency: item?.currency:'symbol' }}</span>
        </div>

        <div class="line--shadow"></div>

        <div class="item__info">
          <strong>{{ 'MARKETPLACE.ITEM_DETAILS.LOCATION' | translate }}</strong> {{ item?.address?.city }},
          {{ item?.address?.state }}
        </div>
      </div>
      <div class="item__date">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.POSTED' | translate }}</strong> {{ item?.createdOn | timeAgo }}
      </div>
      <div class="item__date">
        <strong>{{ 'MARKETPLACE.ITEM_DETAILS.UPDATED' | translate }}</strong> {{ item?.modifiedOn | timeAgo }}
      </div>
      <div *ngIf="!isAuthor">
        <ct-button
          class="mb-3 mt-2"
          variant="flat"
          color="primary"
          [disabled]='item?.sold'
          (click)="contactSeller()"
          *ngIf="!item?.amazonAffiliateUrl?.trim()"
          >{{ 'MARKETPLACE.ITEM_DETAILS.REACH_OUT' | translate }}</ct-button
        >

        <a
          *ngIf="item?.amazonAffiliateUrl?.trim()"
          class="marketplace-item__amazon--url"
          [href]="amazonUrl"
          target="_blank"
        >
          <ct-button class="mb-3 mt-2 marketplace-item__amazon--button" variant="stroked" [fullWidth]="true">
            <ct-icon icon="amazon"></ct-icon>
            <span class="marketplace-item__amazon--text">{{ 'Buy On Amazon' | translate }}</span>
          </ct-button>
        </a>
      </div>
      <div *ngIf="item && !item.amazonAffiliateUrl?.trim()" class="row">
        <agm-map
          class="col-12 col-md-10 col-lg-8 embedded-map mt-2"
          [latitude]="item.address?.lat || 0"
          [longitude]="item.address?.lng || 0"
          [zoom]="16"
          [disableDefaultUI]="false"
        >
          <agm-marker [latitude]="item.address?.lat || 0" [longitude]="item.address?.lng || 0">
            <agm-info-window>
              {{ item.address?.locationLabel || item.address?.formattedAddress }}
              <p>
                <a
                  [href]="
                    'https://www.google.com/maps/dir//' +
                    getEncodedAddress() +
                    '/@' +
                    item.address?.lat +
                    ',' +
                    item.address?.lng
                  "
                  target="_blank"
                  >{{ 'MARKETPLACE.ITEM_DETAILS.NAVIGATE' | translate }}</a
                >
              </p>
            </agm-info-window>
          </agm-marker>
        </agm-map>
        <p class="col-12 item__info">{{ 'MARKETPLACE.ITEM_DETAILS.MAP_NOTE' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="row col-12">
    <div class="col-12">
      <div class="line--shadow"></div>
    </div>
    <div class="col-12" [class.col-md-6]="!item?.amazonAffiliateUrl?.trim()">
      <div class="mb-3 mt-3">
        <p class="mb-0">
          <strong>{{ 'MARKETPLACE.ITEM_DETAILS.DESCRIPTION' | translate }}</strong>
        </p>
        <ct-text-editor-view [content]="item?.description"></ct-text-editor-view>
      </div>
      <div *ngIf="!isAuthor">
        <ct-button
          class="mb-3 mt-2"
          variant="flat"
          color="primary"
          [disabled]='item?.sold'
          (click)="contactSeller()"
          *ngIf="!item?.amazonAffiliateUrl?.trim()"
          >{{ 'MARKETPLACE.ITEM_DETAILS.REACH_OUT' | translate }}</ct-button
        >

        <a
          *ngIf="item?.amazonAffiliateUrl?.trim()"
          class="marketplace-item__amazon--url"
          [href]="amazonUrl"
          target="_blank"
        >
          <ct-button class="mb-3 mt-2 marketplace-item__amazon--button" variant="stroked" [fullWidth]="true">
            <ct-icon icon="amazon"></ct-icon>
            <span class="marketplace-item__amazon--text">{{ 'Buy On Amazon' | translate }}</span>
          </ct-button>
        </a>
      </div>
      <ct-link [link]="['/marketplace/by-merchant']" [queryParams]="{ merchantId: item?.merchantId }">
        <p class="link-text">{{ 'MARKETPLACE.ITEM_DETAILS.SHOW_MORE_ITEMS' | translate }}</p>
      </ct-link>
    </div>
  </div>
</div>
<ct-tag *ngIf="item?.sold" class="sold" [name]="'MARKETPLACE.ITEM_DETAILS.SOLD' | translate"></ct-tag>
