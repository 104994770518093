import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IconType } from '@ct/components';
import { DialogService } from '@ct/components/dialog/services';
import { DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { forkJoin, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { unique } from '../../../../helpers';
import { YoutubeVideo } from '../../../youtube/interfaces';
import { YoutubeVideoAddService, YoutubeVideoApiService } from '../../../youtube/services';
import { TripEntityType } from '../../enums';
import { Trip, TripCampsite } from '../../interfaces';
import { TripApiService } from '../../services/trip-api.service';
import { TripCampsiteApiService } from '../../services/trip-campsite-api.service';
import { CreateTripDialogComponent } from '../create-trip-dialog';
import { CampsiteAddDialogComponent, JournalAddDialogComponent } from './components';

interface AddOption {
  icon: IconType;
  titleKey: string;
  type: TripEntityType;
}

@Component({
  selector: 'ct-add-to-trip-dialog',
  templateUrl: './add-to-trip-dialog.component.html',
  styleUrls: ['./add-to-trip-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class AddToTripDialogComponent {
  public static dialogConfig: MatDialogConfig = {
    width: '30%',
    minWidth: '420px'
  };

  public readonly destroyed$: Observable<void>;
  public readonly subtitle: string;
  public readonly addOptions: AddOption[] = [
    {
      icon: 'tent',
      titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.WAYPOINT',
      type: TripEntityType.Campsite
    },
    {
      icon: 'book',
      titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.JOURNAL_ENTRY',
      type: TripEntityType.Journal
    }
    // {
    //   icon: 'photo',
    //   titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.PHOTOS',
    //   type: TripEntityType.Photos
    // },
    // {
    //   icon: 'video-camera',
    //   titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.VIDEOS',
    //   type: TripEntityType.Video
    // }
  ];

  private trip: Trip;

  private campsiteId?: string;

  constructor(
    private dialogRef: MatDialogRef<AddToTripDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private tripData: Trip,
    private route: ActivatedRoute,
    private tripApiService: TripApiService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private dialogService: DialogService,
    private youtubeVideoApiService: YoutubeVideoApiService,
    private videoAddService: YoutubeVideoAddService
  ) {
    this.trip = {
      ...this.tripData,
      youtubeVideos: [...(this.tripData.youtubeVideos ?? [])],
      photoIds: [...(this.tripData.photoIds ?? [])]
    };
    this.subtitle = this.trip?.title;
    this.campsiteId = this.route.snapshot.queryParams.campsiteId;
  }

  onOptionSelect(option: AddOption) {
    switch (option.type) {
      case TripEntityType.Video:
        return this.onAddYoutubeVideo();
      case TripEntityType.Campsite:
        return this.onAddCampsite();
      case TripEntityType.Journal:
        return this.onAddJournal();
    }
  }

  onAddYoutubeVideo() {
    const dRef = this.videoAddService.showVideoAddDialog({
      titleKey: 'MY_ACCOUNT.ADD_VIDEOS',
      firstTabKey: 'MY_ACCOUNT.UPLOAD_VIDEO',
      secondTabKey: 'MY_ACCOUNT.MY_VIDEOS',
      selectable: true,
      getAllVideosFn: () => this.youtubeVideoApiService.getAllMyVideos(),
      addVideoLinkFn: (youtubeId: string) => {
        const payload = {
          youtubeId,
          tripIds: [String(this.trip.id)],
          authorId: this.trip.authorId,
          blogPostIds: [],
          campsiteIds: [] as string[]
        };
        if (this.campsiteId) {
          payload.campsiteIds.push(this.campsiteId);
        }
        return this.youtubeVideoApiService.create(payload);
      }
    });
    dRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap((videos: YoutubeVideo[]) => {
          const newVideoIds = (videos || []).map((video) => video.youtubeId) as string[];
          this.trip.youtubeVideos = unique([...(this.trip.youtubeVideos || []), ...newVideoIds]);
          const updateRequests = [
            this.tripApiService.update(this.trip.id as string, {
              youtubeVideos: this.trip.youtubeVideos
            })
          ];

          if (this.campsiteId) {
            const campsiteUpdateRequest = this.tripCampsiteApiService
              .getById(this.trip.id as string, this.campsiteId)
              .pipe(
                switchMap((campsite) => {
                  const videoIds = this.mergeItemsWithId(videos, campsite.videoIds);
                  return this.tripCampsiteApiService.update(
                    this.trip.id as string,
                    this.campsiteId as string,
                    {
                      videoIds
                    } as TripCampsite
                  );
                })
              );
            updateRequests.push(campsiteUpdateRequest);
          }
          return forkJoin(updateRequests);
        })
      )
      .subscribe((results) => {
        if (results.length > 0) {
          const entityWithVideos = results.pop();
          entityWithVideos.youtubeVideos;
          this.dialogRef.close({ reload: true, navigateTo: TripEntityType.Video });
        } else {
          this.dialogRef.close({ reload: false });
        }
      });
  }

  onAddCampsite() {
    this.dialogService
      .open(CampsiteAddDialogComponent, { data: { trip: this.trip } })
      .afterClosed()
      .pipe(
        take(1),
        filter((value) => Boolean(value))
      )
      .subscribe(({ campsite }) => {
        const response: any = { reload: Boolean(campsite) };
        if (campsite) {
          response.navigateTo = TripEntityType.Campsite;
        }

        this.dialogRef.close(response);
      });
  }

  onAddJournal() {
    this.dialogService
      .open(JournalAddDialogComponent, { data: this.trip })
      .afterClosed()
      .pipe(
        take(1),
        filter((value) => Boolean(value))
      )
      .subscribe(({ reload }) => {
        const response: any = { reload };
        if (reload) {
          response.navigateTo = TripEntityType.Journal;
        }

        this.dialogRef.close(response);
      });
  }

  openNewTripDialog() {
    this.dialogService.open(CreateTripDialogComponent);
    this.dialogRef.close();
  }

  private mergeItemsWithId<T extends { id?: string }>(items: T[], oldIds: string[]): string[] {
    const newIdsList = (items || []).map((photo) => photo.id);
    let idsList = [...newIdsList, ...(oldIds || [])];
    idsList = idsList.map((id) => String(id));
    return idsList as string[];
  }
}
