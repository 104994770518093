import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, RequestRange } from '@ct/core';
import { environment } from '@ct/environment';
import { SetActiveOptions } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TripJournalEntryFactory } from '../factories';
import { TripJournalEntry } from '../interfaces';
import { JournalEntryStore } from './state';

const endpoint = environment.journalEntryApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class JournalEntryApiService extends BaseHttpService {
  constructor(
    protected httpClient: HttpClient,
    protected tripJournalEntryFactory: TripJournalEntryFactory,
    protected store: JournalEntryStore
  ) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    tripId,
    campsiteId
  }: {
    range?: RequestRange;
    tripId?: string;
    campsiteId?: string;
  }): Observable<TripJournalEntry[]> {
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (campsiteId !== undefined && campsiteId !== null && campsiteId !== 'all') {
      params = params.append('campsiteId', campsiteId);
    }
    if (tripId !== undefined && tripId !== null) {
      params = params.append('tripId', tripId);
    }
    this.store.setLoading(true);
    return this.get(``, params).pipe(
      tap((journalEntries) => {
        this.store.setLoading(false);
        if (journalEntries?.length) {
          this.store.upsertMany(journalEntries);
        }
      })
    );
  }

  getById(journalEntryId: string) {
    this.store.setLoading(true);
    return this.get(`${journalEntryId}`).pipe(
      tap((journalEntry) => {
        this.store.upsert(journalEntryId, journalEntry);
        this.store.setLoading(false);
        this.setActive(journalEntry);
      })
    );
  }

  create(entry: Partial<TripJournalEntry>) {
    const request = this.tripJournalEntryFactory.toRequest(entry);
    return this.post(``, request);
  }

  update(entryId: string, entry: TripJournalEntry) {
    const request = this.tripJournalEntryFactory.toRequest(entry);
    return this.patch(`${entryId}`, request);
  }

  remove(entryId: string) {
    this.store.setLoading(true);
    return this.delete(`${entryId}`).pipe(
      tap(() => {
        this.store.remove(entryId);
        this.store.setLoading(false);
      })
    );
  }

  setActive(id: string) {
    this.store.setActive(id as SetActiveOptions); // exactly same code doesn't require type cast in tag-api.service...
  }
}
