import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'ct-ad-banner-small',
  templateUrl: './ad-banner-small.component.html',
  styleUrls: ['./ad-banner-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdBannerSmallComponent {
  @Input() public readonly image: string;
  @Input() public readonly title: string;
  @Input() public readonly extraActionLabel: string;
  @Input() public readonly extraActionLink: string[];
  @Input() public readonly extraActionQueryParams: Params;

  @Input() public readonly showLine: boolean = true;
}
