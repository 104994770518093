import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestRange } from '@ct/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TripJournalEntryFactory } from '../factories';
import { TripJournalEntry } from '../interfaces';
import { JournalEntryApiService } from './journal-entry-api.service';
import { JournalEntryStore } from './state';

@Injectable({ providedIn: 'root' })
export class TripJournalEntryApiService extends JournalEntryApiService {
  constructor(
    protected httpClient: HttpClient,
    protected tripJournalEntryFactory: TripJournalEntryFactory,
    protected store: JournalEntryStore
  ) {
    super(httpClient, tripJournalEntryFactory, store);
  }

  getAllByTripId(
    tripId: string,
    {
      range,
      campsiteId
    }: {
      range?: RequestRange;
      campsiteId?: string;
    }
  ): Observable<TripJournalEntry[]> {
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (campsiteId !== undefined && campsiteId !== null && campsiteId !== 'all') {
      params = params.append('campsiteId', campsiteId);
    }
    this.store.setLoading(true);
    return this.get(`${tripId}/journal-entries`, params).pipe(
      tap((journalEntries) => {
        this.store.setLoading(false);
        if (journalEntries?.length) {
          this.store.upsertMany(journalEntries);
        }
      })
    );
  }

  createInTrip(tripId: string, entry: TripJournalEntry) {
    return this.create({ ...entry, tripId });
  }
}
