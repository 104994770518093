<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-user-header [user]="loggedInUser$ | async" ctHeader></ct-user-header>

  <div ctContent>
    <ct-tab-header
      class="d-none d-md-flex"
      style="margin-bottom: -1px"
      [tabs]="tabs"
      (tabChange)="onFilterChanged($event)"
    >
      <ct-icon ctTabPrefix class="add__icon" title="Add New Memory" icon="add-circle" (click)="addMemory()"></ct-icon>
    </ct-tab-header>
    <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)">
      <ct-icon ctTabPrefix class="add__icon" icon="add-circle" (click)="addMemory()"></ct-icon>
    </ct-tabs-mobile>
    <ng-container *ngIf="hasContent; else noData">
      <div
        class="row px-4 px-md-5 mb-4 pt-4 with-background flex-column"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      >
        <div *ngFor="let timeline of timelines; trackBy: trackById" class="mb-4 timeline-entity col-12 main-content">
          <ng-container *ngTemplateOutlet="elementHeader; context: { entity: timeline }"></ng-container>

          <p *ngIf="timeline.body" class="timeline-entity__message">{{ timeline.body }}</p>
          <ng-container *ngIf="timeline.type === timelineType.Journal">
            <ct-journal-entry
              *ngIf="timeline.journal; else noEntity"
              [journalEntry]="toJournalEntry(timeline.journal)"
              [canEdit]="false"
            ></ct-journal-entry>
          </ng-container>
          <ng-container *ngIf="timeline.type === timelineType.BlogPost">
            <ct-blog-post-card
              *ngIf="timeline.story; else noEntity"
              [post]="timeline.story"
              [showLink]="true"
              [showStatus]="true"
              imgHeight="220px"
              imgWidth="100%"
              [link]="getStoryLink(timeline)"
            ></ct-blog-post-card>
          </ng-container>
          <ng-container *ngIf="timeline.type === timelineType.Trip">
            <ct-trip-card
              *ngIf="timeline.trip; else noEntity"
              class="cursor--pointer"
              [trip]="timeline.trip"
              [link]="getTripLink(timeline)"
            ></ct-trip-card>
          </ng-container>
          <ng-container *ngTemplateOutlet="elementFooter; context: { entity: timeline }"></ng-container>
        </div>
        <ng-container *ngIf="!timelines?.length && isMyTripsTab">
          <ng-container *ngTemplateOutlet="noData"></ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="showLoadButton"
        class="load-more"
        [ngClass]="{ disabled: loading }"
        (click)="onScroll()"
        disabled="loading"
      >
        Load More
      </div>
    </ng-container>
    <ng-template
      #elementHeader
      let-entity="entity"
      let-author="entity?.author"
      let-type="entity?.type"
      let-createdOn="entity?.createdOn"
    >
      <div class="header row">
        <div class="user col-12">
          <ct-user-avatar
            class="user__avatar"
            [src]="getCroppedThumbPublicUrl(author?.photo?.publicUrl)"
            width="32px"
            height="32px"
          ></ct-user-avatar>
          <p class="user__link">
            <span
              *ngIf="author; else deletedUser"
              class="user__name"
              [routerLink]="['/user-profile']"
              [queryParams]="{ userId: author.userId }"
              >{{ author.username }}</span
            >
            <ng-template #deletedUser>
              <span class="user__name">Deleted User</span>
            </ng-template>
            <span class="user__subtitle" [routerLink]="['.', entity.id]">
              <ng-container *ngIf="type === timelineType.Trip">
                shared the trip on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </ng-container>
              <ng-container *ngIf="type === timelineType.Journal">
                shared the memory on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </ng-container>
              <ng-container *ngIf="type === timelineType.BlogPost">
                shared the story on {{ createdOn ?? entity.date | date : 'MMM d yyyy, HH:mm' }}
              </ng-container>
              <ng-container *ngIf="entity?.metadata?.trip">
                on <span class="highlight">{{ entity.metadata.trip.title }}</span> trip
              </ng-container>
              <ng-container *ngIf="entity?.metadata?.waypoint">
                at <span class="highlight">{{ entity.metadata.waypoint.title }}</span>
              </ng-container>
            </span>
          </p>
        </div>
      </div>
    </ng-template>

    <ng-template #elementFooter let-entity="entity">
      <div class="row">
        <div class="col-12 extra-features">
          <ct-like
            class="extra-features__action"
            [likes]="entity.metadata?.likes"
            [user]="loggedInUser$ | async"
            (like)="onLike(entity)"
            (dislike)="onDislike(entity)"
          ></ct-like>
          <div class="extra-features__action" (click)="toggleComments(entity)">
            <ct-icon icon="chat"></ct-icon>
            {{ entity.metadata?.threads?.length }}
          </div>
        </div>
      </div>
      <ct-entity-comments
        *ngIf="entity.id === activeCommentsEntityId"
        class="mt-4"
        [entityId]="entity?.metadataId"
        [entityType]="getEntityType(entity)"
        [disableComments]="false"
        [fetchOnInit]="false"
        [threads]="entity.metadata.threads"
        [user]="loggedInUser$ | async"
      ></ct-entity-comments>
    </ng-template>

    <ng-template #noEntity>
      <div class="no-entity">
        {{ 'Shared entity is not available anymore' | translate }}
      </div>
    </ng-template>
    <ng-template #noData>
      <ct-empty-call-to-action
        [hasButton]="false"
        emptyMessageTextKey="There is nothing in your feed yet. Start sharing, joining groups or making friends on the platform"
      ></ct-empty-call-to-action>
    </ng-template>
  </div>
</ct-layout>
