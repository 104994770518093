import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { DialogService } from '@ct/components/dialog/services';
import { ImageItem, ThumbnailsPosition } from '@ct/components/gallery';
import {
  BlogPost,
  BlogPostStatus,
  BlogPostWithFeaturedImage,
  CanonicalService,
  DestroyableFeature,
  FacebookCardType,
  Features,
  JsonLdService,
  LikeSummary,
  MetaTagService,
  SeriesWithStories,
  TitleService,
  TwitterCardType,
  UserProfile
} from '@ct/core';
import { environment } from '@ct/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { EntityType } from '../../../../enums';
import {
  entitySlugUrl,
  getBiggerPublicUrl,
  getCroppedThumbPublicUrl,
  getYoutubeId,
  stripTags
} from '../../../../helpers';
import { ImageUploadApiService } from '../../../../services';
import { ReportInappropriateDialogComponent } from '../../../report-inappropriate-dialog';
import { BlogPostPhotoViewType } from '../../enums';
import { LikeApiService, SeriesApiService } from '../../services';

const BANNER_STATUSES = new Set([
  BlogPostStatus.WaitingApproval,
  BlogPostStatus.FlaggedForReview,
  BlogPostStatus.Suspended
]);

const ADMIN_STATUSES = new Set([BlogPostStatus.WaitingApproval, BlogPostStatus.Suspended]);

@Component({
  selector: 'ct-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class BlogPostComponent implements OnChanges, OnDestroy {
  public readonly destroyed$: Observable<void>;

  @Input() public post: BlogPostWithFeaturedImage | null;
  @Input() public currentUser: UserProfile | null;
  @Input() public customSharingUrl: boolean;
  @Input() public isAdminView: boolean;
  @Input() public editLinkPrefix = '/my-account/writings';
  @Input() public photoViewType: BlogPostPhotoViewType = BlogPostPhotoViewType.Gallery;

  @Output() readonly changeStatus = new EventEmitter<BlogPostStatus>();
  @Output() readonly refresh = new EventEmitter<void>();

  get featuredPublicUrl() {
    return getBiggerPublicUrl(this.post?.featuredPhoto?.publicUrl);
  }

  get imageThumbnail(): string | undefined {
    return this.post?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.post?.featuredYoutubeVideo)}/default.jpg`
      : this.post?.featuredPhoto?.xsPreview;
  }

  get imagePublicUrl(): string | undefined {
    return this.post?.featuredYoutubeVideo
      ? `https://img.youtube.com/vi/${getYoutubeId(this.post?.featuredYoutubeVideo)}/maxresdefault.jpg`
      : this.featuredPublicUrl;
  }

  public images: ImageItem[] = [];
  public thumbnailsPosition = ThumbnailsPosition.Bottom;
  public readonly blogPostStatus = BlogPostStatus;

  public selectedYoutubeVideo: string | undefined;
  public likes: LikeSummary;

  get status(): BlogPostStatus {
    const isInReview = !ADMIN_STATUSES.has(this.post?.status as BlogPostStatus) && (this.post?.flags as number) > 0;
    return isInReview ? BlogPostStatus.FlaggedForReview : (this.post?.status as BlogPostStatus);
  }

  get isGallery() {
    return this.photoViewType === BlogPostPhotoViewType.Gallery;
  }

  get showBanner() {
    return !this.isAdminView && this.canEdit && BANNER_STATUSES.has(this.status);
  }

  get blogUrl(): string {
    // TODO: window fix for SSR, should be replaced with APP level injection.
    let href = undefined;
    let location = undefined;
    if (typeof globalThis !== 'undefined' && globalThis?.location) {
      location = globalThis.location.origin;
      href = globalThis.location.href;
    }
    const baseUrl = location || environment.webUrl;
    const currentUrl = href || 'none';

    const shareUrl = entitySlugUrl(`${baseUrl}/stories/`, this.post as BlogPost);

    return this.customSharingUrl ? shareUrl : currentUrl;
  }

  get canEdit(): boolean {
    return this.post?.authorId === this.currentUser?.userId || this.isAdminView;
  }

  get editLink(): string[] {
    if (this.post) {
      const url = entitySlugUrl('', this.post);
      return [`${this.editLinkPrefix}/edit/`, url];
    }
    return [];
  }

  get showSeries(): boolean {
    const length = this.series?.stories?.length ?? 0;
    return length > 1;
  }

  protected series: SeriesWithStories;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: TitleService,
    private metaTagService: MetaTagService,
    private canonicalService: CanonicalService,
    private jsonLdService: JsonLdService,
    private imageUploadApiService: ImageUploadApiService,
    private seriesApiService: SeriesApiService,
    private blogPostLikeApiService: LikeApiService,
    private dialogService: DialogService
  ) {}

  ngOnChanges({ post }: SimpleChanges) {
    if (post?.currentValue !== post?.previousValue) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.jsonLdService.removeStructuredData('structured-data-story');
    this.metaTagService.addKeywords();
  }

  init() {
    this.images = [];
    if (this.post) {
      this.setSeo(this.post as BlogPostWithFeaturedImage);
      this.loadPhotos(this.post as BlogPostWithFeaturedImage);
      if (this.post.youtubeVideos?.length > 0) {
        this.selectedYoutubeVideo = this.post.youtubeVideos[0];
        this.changeDetectorRef.detectChanges();
      }
      this.initLikes();
      if (this.post.seriesId) {
        this.loadSeries();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  setSeo(post: BlogPostWithFeaturedImage): void {
    const canonicalUrl = entitySlugUrl('/stories/', post);
    this.titleService.setTitle(post?.title);
    this.canonicalService.setURL(canonicalUrl);
    const stripedHtml = post?.body.replace(/<[^>]+>/g, '').slice(0, 200);
    this.metaTagService.setMetaDescription(stripedHtml);
    this.metaTagService.addKeywords(post?.featuredPhotoKeywords?.join(', '));

    this.metaTagService.setSocialMedia({
      title: post?.title,
      description: stripTags(post?.body),
      twitterCardType: TwitterCardType.SummaryLargeImage,
      facebookCardType: FacebookCardType.Article,
      image: this.imagePublicUrl as string
    });

    this.jsonLdService.insertSchema(JsonLdService.blogPostingSchema(post), 'structured-data-story');
  }

  loadPhotos(post: BlogPostWithFeaturedImage) {
    const photosRequest$ = post.photoIds?.length
      ? forkJoin(post.photoIds?.map((photoId: string) => this.imageUploadApiService.getById(photoId)))
      : of([]);
    photosRequest$
      .pipe(map((photos) => photos.map((photo: any) => ({ ...photo, id: String(photo?.id) }))))
      .subscribe((photos) => {
        this.post = {
          ...this.post,
          photos
        } as BlogPostWithFeaturedImage;
        this.images = this.post?.photos?.map(
          (photo) =>
            new ImageItem({
              original: photo.path,
              src: getBiggerPublicUrl(photo.publicUrl) ?? photo.path,
              thumb: getCroppedThumbPublicUrl(photo.publicUrl),
              data: photo
            })
        ) as ImageItem[];
        this.changeDetectorRef.detectChanges();
      });
  }

  selectVideo(video: string, index?: number) {
    this.selectedYoutubeVideo = video;
  }

  initLikes() {
    this.blogPostLikeApiService
      .getAll({
        entityId: this.post?.id as string,
        entityType: EntityType.Story
      })
      .subscribe(this.loadLikes);
  }

  loadLikes = (likes: LikeSummary) => {
    this.likes = likes;
    this.changeDetectorRef.detectChanges();
  };

  onLike($event: any) {
    this.blogPostLikeApiService
      .like({
        entityId: this.post?.id as string,
        entityType: EntityType.Story
      })
      .subscribe(this.loadLikes);
  }

  flagPost() {
    this.dialogService
      .open(ReportInappropriateDialogComponent, {
        data: this.post?.id
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.refresh.emit();
      });
  }

  onDislike($event: any) {
    this.blogPostLikeApiService
      .dislike({
        entityId: this.post?.id as string,
        userId: this.currentUser?.userId as string
      })
      .subscribe(this.loadLikes);
  }

  onPublish() {
    this.changeStatus.emit(BlogPostStatus.Published);
  }

  onDraft() {
    this.changeStatus.emit(BlogPostStatus.Draft);
  }

  onSuspend() {
    this.changeStatus.emit(BlogPostStatus.Suspended);
  }

  private loadSeries() {
    this.seriesApiService.getById(this.post?.seriesId as string).subscribe((series) => {
      this.series = { ...series, stories: series.stories };
      this.changeDetectorRef.markForCheck();
    });
  }
}
