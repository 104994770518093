<div class="row">
  <ng-container *ngIf="post">
    <ct-writing-card
      [story]="post"
      [showLastEdit]="false"
      [canShare]="false"
      [canOpen]="false"
      (share)="shareStory(post)"
      class="col-12"
    >
    </ct-writing-card>
    <div class="col-12 col-md-9 pt-4">
      <ct-ad-banner-wide></ct-ad-banner-wide>
      <ct-blog-post
        [currentUser]="currentUser$ | async"
        [post]="post"
        [photoViewType]="photoViewType"
        (refresh)="onRefresh()"
      ></ct-blog-post>
    </div>
  </ng-container>
  <div class="col-12 col-md-3 pt-2 pt-md-0">
    <ct-blog-sidebar></ct-blog-sidebar>
  </div>
  <div *ngIf="(isLoggedIn$ | async) === false" class="col-12">
    <ct-blog-post-signup-banner></ct-blog-post-signup-banner>
  </div>
</div>
