import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, TabHeader } from '@ct/components';
import { Features, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { AddMediaDialogComponent } from '../add-media-dialog';

enum Media {
  Photos = 'photos',
  Videos = 'videos'
}

@Component({
  selector: 'ct-my-account-media',
  templateUrl: './my-account-media.component.html',
  styleUrls: ['./my-account-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountMediaComponent implements OnDestroy {
  public readonly destroyed$: Subject<unknown> = new Subject();
  public titleConfig: TitleConfig = {
    titleKey: 'MY_ACCOUNT.MY_MEDIA'
  };

  public tabs: TabHeader[] = [
    { name: Media.Photos, labelKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS', selected: true },
    { name: Media.Videos, labelKey: 'MAIN.FEATURES.MY_ACCOUNT_VIDEOS' }
  ];

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  constructor(
    private router: Router,
    private routeSnapshot: ActivatedRoute,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    let route: ActivatedRoute = this.routeSnapshot.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const path = route.snapshot?.url[0]?.path;
    if (path) {
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        selected: tab.name === path
      }));
    }

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const path = route.snapshot.url[0]?.path;
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onFilterChanged(tab: TabHeader) {
    this.router.navigate(['my-account/media', tab.name]);
  }

  addItems() {
    this.onAddPhoto();
  }

  onAddPhoto() {
    this.dialogService
      .open(AddMediaDialogComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        const activeTab = this.tabs.find(({ selected }) => selected) as TabHeader;
        this.router.navigate(['my-account/media', activeTab.name]);
      });
  }
}
