import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageModule } from '../image';
import { LinkModule } from './../link';
import { AdBannerSmallComponent } from './ad-banner-small';
import { AdBannerWideComponent } from './ad-banner-wide';

@NgModule({
  declarations: [AdBannerSmallComponent, AdBannerWideComponent],
  imports: [CommonModule, LinkModule, ImageModule],
  exports: [AdBannerSmallComponent, AdBannerWideComponent]
})
export class AdBannersModule {}
