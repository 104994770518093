<a *ngIf="isExternalLink" [href]="url" target='_blank'>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>
<a
  *ngIf="!isExternalLink"
  [routerLink]="url"
  [queryParams]="queryParams"
  [queryParamsHandling]="queryParamsHandling"
  [routerLinkActive]="['active']"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
