<ng-container *ngIf="(isLoggedIn$ | async) === true || isSimple; else defaultLayout">
  <router-outlet></router-outlet>
</ng-container>

<ct-version-check></ct-version-check>

<ng-template #defaultLayout>
  <header>
    <ng-container *ngIf="isTransparent; else defaultHeader">
      <ct-home-header></ct-home-header>
    </ng-container>
    <ng-template #defaultHeader>
      <ct-header [showSearch]='shouldShowSearch'></ct-header>
    </ng-template>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <ct-footer></ct-footer>
  </footer>
</ng-template>
