import { ChangeDetectionStrategy, Component } from '@angular/core';

interface AdBanner {
  url: string;
  image: string;
}

const ADS_LIST: AdBanner[] = [
  {
    url: 'https://autoclubsouth.aaa.com/insurance/rv-insurance.aspx',
    image: '00_aaa_rv_insurance.png',
  },
  {
    url: 'https://www.hwhrv.com/rv-insurance',
    image: '01-Banner-holiday-world-rv-priority-rv-insurance.jpg',
  },
  {
    url: 'https://www.alinsagency.com/rv-insurance.html',
    image: '02-Banner-campers-RV_orig.png',
  },
  {
    url: 'https://mycfipartner.com/rv-insurance/',
    image: '03-Banner-RV-Insurance-Dade-City-FL-845x321.png',
  },
  {
    url: 'https://www.foremost.com/products/motor-home/',
    image: '04-Banner-USA-motor-home.jpeg',
  },
  {
    url: 'https://www.totalinsurancesolutionsllc.com/personal/recreational-vehicle-insurance/',
    image: '05-Banner-RV-Insurance-Winter_Banner.jpeg',
  },

]

@Component({
  selector: 'ct-ad-banner-wide',
  templateUrl: './ad-banner-wide.component.html',
  styleUrls: ['./ad-banner-wide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdBannerWideComponent {
  protected banner: AdBanner;
  constructor() {
    this.banner = ADS_LIST[getRandomInt(0, ADS_LIST.length - 1)] ?? ADS_LIST[0];
  }
}

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
