<div
  *ctContext="{ loading: loading$ | async, loadedVideo: loadedVideo$ | async } as context"
  class="video-item-preview__wrapper"
  [class.video-item-preview__wrapper--disabled]="video.transcodingJobStatus !== transcodingState.SUCCEEDED"
>
  <div *ngIf="!context.loadedVideo" class="video-item-preview__thumbnail" (click)="!context.loading && onPlayVideo()">
    <ct-progressive-image
      *ngIf="video.transcodingJobStatus === transcodingState.SUCCEEDED && video.thumbnailUrl"
      [publicUrl]="video.thumbnailUrl"
      width="100%"
      height="50vh"
      objectFit="contain"
    />
    <ct-icon *ngIf="!context.loading" icon="circle-play" class="play-icon"></ct-icon>
    <ct-spinner *ngIf="context.loading" class="play-icon"></ct-spinner>
  </div>
  <ct-my-video-player
    *ngIf="context.loadedVideo"
    [hlsUrl]="m3u8 && decodeUrl(m3u8)"
    [dashUrl]="mpd && decodeUrl(mpd)"
    [autoplay]="true"
    style="height: 50vh"
    class="video-item-preview__player"
  ></ct-my-video-player>
  <h4 class="col-12 mt-4 processing-text" *ngIf="video.transcodingJobStatus !== transcodingState.SUCCEEDED">
    Video is being processed. Please, refresh page in a minute to check status.
  </h4>
</div>
