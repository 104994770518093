import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  DialogService,
  ImageUploadQueueService,
  ImageUploadService,
  MediaSelectDialogComponent,
  SelectOption,
  SpinnerService
} from '@ct/components';
import { DialogButton } from '@ct/components/dialog';
import {
  ChannelVideoUploadApiService,
  DestroyableFeature,
  Features,
  FormStateDispatcher,
  Group,
  GroupFilterType,
  LocalStorageService,
  Tag,
  TranscodingState,
  UploadedImage,
  VideoOrientation,
  VideoUploadEntity
} from '@ct/core';
import { TranslateService } from '@ngx-translate/core';
import { QuillModules } from 'ngx-quill';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, finalize, first, map, shareReplay, switchMap, take } from 'rxjs/operators';

import { BaseAutosaveFormComponent } from '../../../../../../classes';
import { ImageUploadApiService } from '../../../../../../services/image-upload-api.service';
import { MyAccountPhotoApiService } from '../../../../../../services/my-account-photos-api.service';
import { TagApiService } from '../../../../../../services/tag-api.service';
import { GroupApiService } from '../../../../../group-shared/services/group-api.service';
import { VideoPlayerDialogComponent } from '../../../../../video-player-dialog';
import { Trip } from '../../../../interfaces';
import { TripCampsiteApiService, TripJournalEntryApiService } from '../../../../services';

const QUILL_MODULES_CONFIG: QuillModules = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', { list: 'bullet' }, 'link', 'image']]
};

const TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG = {
  autosaveKey: 'TRIP_JOURNAL_ENTRY_CREATE_AUTOSAVE',
  excludeFormFields: [],
  dialogConfig: {
    titleKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.TITLE',
    messageKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.MESSAGE',
    confirmKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CONFIRM',
    cancelKey: 'MY_ACCOUNT.MY_TRIPS_FEATURE.AUTOSAVE_DIALOG.CANCEL'
  }
};

@Component({
  selector: 'ct-journal-add-dialog',
  templateUrl: './journal-add-dialog.component.html',
  styleUrls: ['./journal-add-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class JournalAddDialogComponent extends BaseAutosaveFormComponent implements OnInit, OnDestroy {
  public readonly destroyed$: Observable<void>;
  public readonly modules: QuillModules = QUILL_MODULES_CONFIG;

  public readonly form: UntypedFormGroup;

  public isLoading = false;

  public campsites$: Observable<SelectOption[]> = this.tripCampsiteApiService
    .getAllByTripId(this.tripId as string)
    .pipe(
      map((campsites) => campsites.map((campsite) => ({ value: campsite.id, label: campsite.title } as SelectOption)))
    );
  public groups$: Observable<Group[]> = this.groupApiService
    .findAllMemberGroups({ filterType: GroupFilterType.MemberOf })
    .pipe(shareReplay(1));

  public buttons: DialogButton[] = [
    {
      labelKey: 'COMMON.CANCEL',
      color: 'primary',
      clicked: () => this.onCancel()
    },
    {
      labelKey: 'COMMON.SAVE',
      clicked: () => {
        this.onSave();
      }
    }
  ];

  get tripId(): string {
    return this.trip.id as string;
  }

  get photos() {
    return this.form.controls.photos?.value;
  }

  protected readonly mediaSelectError$ = new BehaviorSubject<boolean>(false);
  protected readonly uploadingMedia$ = new BehaviorSubject<boolean>(false);
  private readonly campsiteId?: string;
  private interval: number;

  public readonly optionsFn = (match: string) => this.tagApiService.getAll(match);

  public readonly createFn = (name: string) => this.tagApiService.create(name);

  public readonly checkFn = (name: string) => this.tagApiService.getByName(name);

  public readonly labelFn = ({ name }: Tag) => name as string;

  public readonly optionsGroupFn = (match: string) =>
    this.groups$.pipe(
      map((groups) =>
        groups
          .map((group) => ({ ...group, name: group.title }))
          .filter(({ title }) => title.toLowerCase().includes(match?.toLowerCase()))
      )
    );

  public readonly labelGroupFn = ({ title }: Group) => title;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly trip: Trip,
    protected localStorageService: LocalStorageService,
    protected translateService: TranslateService,
    protected dialogService: DialogService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<JournalAddDialogComponent>,
    private formState: FormStateDispatcher,
    private spinnerService: SpinnerService,
    private imageUploadApiService: ImageUploadApiService,
    private tripJournalEntryApiService: TripJournalEntryApiService,
    private tripCampsiteApiService: TripCampsiteApiService,
    private imageUploadService: ImageUploadService,
    private myAccountPhotoApiService: MyAccountPhotoApiService,
    private tagApiService: TagApiService,
    private groupApiService: GroupApiService,
    private imageUploadQueueService: ImageUploadQueueService,
    private videoUploadApiService: ChannelVideoUploadApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar
  ) {
    super(localStorageService, dialogService, translateService, TRIP_JOURNAL_ENTRY_AUTOSAVE_CONFIG);
    this.campsiteId = this.route.snapshot.queryParams.campsiteId;

    this.form = new UntypedFormGroup({
      tags: new UntypedFormControl([]),
      body: new UntypedFormControl('', [Validators.required]),
      campsiteId: new UntypedFormControl(this.campsiteId),
      date: new UntypedFormControl(null),
      photos: new UntypedFormControl([]),
      videos: new UntypedFormControl([]),
      groups: new UntypedFormControl([]),
      isPublic: new FormControl(false)
    });
  }

  ngOnInit() {
    this.startVideosStatusListener();
    this.setForm(this.form);
    this.getAutosavedItem()
      .pipe(take(1))
      .subscribe((autosavedPost) => {
        if (!autosavedPost) {
          this.stopAutosave();
          this.startAutosave();
          return;
        }
        this.form.patchValue(autosavedPost);
        this.startAutosave();
      });
  }

  ngOnDestroy() {
    this.stopVideosStatusListener();
  }

  onSave() {
    this.formState.onSubmit.notify();

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    const { isPublic, ...entry } = this.form.getRawValue();

    return this.tripJournalEntryApiService
      .createInTrip(this.tripId, { ...entry, isPrivate: !isPublic })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.spinnerService.hide();
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe(() => {
        this.stopAutosave();
        this.dialogRef.close({ reload: true });
      });
  }

  onCancel() {
    this.stopAutosave();
    this.dialogRef.close({ reload: false });
  }

  onBack() {
    this.stopAutosave();
    this.dialogRef.close();
  }

  async onUploadVideo(file: File) {
    let orientation = VideoOrientation.Landscape;
    let width = 0;
    let height = 0;
    const duration = await this.onGetDuration(file);
    try {
      const result = (await this.onGetOrientation(file)) ?? { width, height, orientation };
      orientation = result.orientation;
      width = result.width;
      height = result.height;
    } catch (e) {
      return this.snackBar.open(`Error parsing video "${file.name}". Please, select another video.`, 'close', {
        duration: 10000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
    }
    this.spinnerService.show({ instant: true });
    this.videoUploadApiService
      .getUploadLink({ orientation, duration, width, height, fileName: file.name })
      .pipe(
        switchMap(({ uploadLink, ...video }) => {
          return this.videoUploadApiService.useUploadLink(file, uploadLink).pipe(map(() => video));
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.uploadingMedia$.next(false);
        }),
        catchError((err) => {
          if (err.error.error && err.error.message) {
            this.snackBar.open(`${err.error.error}: ${err.error.message}`, 'close', {
              duration: 10000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
          return of(null);
        })
      )
      .subscribe((video: VideoUploadEntity | null) => {
        if (video) {
          this.form.controls.videos.setValue([video]);
        }
      });
  }

  onAddMedia(files: File[]): void {
    this.mediaSelectError$.next(false);
    const isVideoUpload = files.some((file) => file.type.includes('video'));
    if (files.length > 1 && isVideoUpload) {
      this.mediaSelectError$.next(true);
      return;
    }

    this.uploadingMedia$.next(true);

    if (isVideoUpload) {
      this.onUploadVideo(files[0]);
    } else {
      this.imageUploadQueueService.onStartUpload(files, {
        uploadFn: (file: File) => this.imageUploadApiService.uploadWithProgress(file)
      });
      // TODO: verofy if this long check inside `first` pipe is needed
      this.imageUploadQueueService.loading$
        .pipe(first((value) => !value && this.imageUploadQueueService.uploadedImages.every(Boolean)))
        .subscribe(() => {
          const photos = this.imageUploadQueueService.uploadedImages.map((image) => image.data).filter(Boolean);
          this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
          this.uploadingMedia$.next(false);
        });
    }
  }

  onAddPhotos() {
    this.imageUploadService
      .showUploadDialog({
        titleKey: 'MY_ACCOUNT.ADD_PHOTOS',
        multiple: true,
        selectable: true,
        firstTabKey: 'MY_ACCOUNT.UPLOAD_PHOTOS',
        secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
        getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
        uploadFn: (file: File) => this.imageUploadApiService.uploadWithProgress(file)
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((photos) => {
        this.form.controls.photos.patchValue([...this.form.controls.photos.value, ...photos]);
        this.changeDetectorRef.detectChanges();
      });
  }

  onRemovePhoto({ id }: UploadedImage) {
    const photos = this.photos.filter((photo: UploadedImage) => photo.id !== id);
    this.form.controls.photos.patchValue(photos);
    this.changeDetectorRef.detectChanges();
  }

  onPlayVideo(): void {
    this.dialogService.open(VideoPlayerDialogComponent, { data: this.form.controls.videos.value[0] });
  }

  onRemoveVideo(): void {
    this.form.get('videos')?.patchValue([]);
  }

  onSelectExisting(): void {
    this.dialogService
      .open(MediaSelectDialogComponent, {
        data: {
          config: {
            titleKey: 'MY_ACCOUNT.SELECT_FILES',
            multiple: true,
            selectable: true,
            firstTabKey: 'MY_ACCOUNT.MY_VIDEOS',
            secondTabKey: 'MY_ACCOUNT.MY_PHOTOS',
            getAllImagesFn: () => this.myAccountPhotoApiService.getAllMyPhotos(),
            getAllVideosFn: () => this.videoUploadApiService.getAllVideos()
          }
        }
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((result) => {
        const { video, images } = result as unknown as { video: VideoUploadEntity; images: UploadedImage[] };
        if (video) {
          this.form.controls.videos.setValue([video]);
          this.form.controls.photos.setValue([]);
        } else if (images) {
          this.form.controls.videos.setValue([]);
          this.form.controls.photos.setValue(images);
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  async onGetOrientation(videoFile: Blob) {
    // eslint-disable-next-line no-prototype-builtins
    if (HTMLVideoElement.prototype.hasOwnProperty('requestVideoFrameCallback')) {
      const video = await this.getVideoElement(videoFile);
      video.muted = true;
      video.playbackRate = 16;
      const { videoWidth, videoHeight } = video;
      video.pause();
      window.URL.revokeObjectURL(video.src);
      video.remove();
      const orientation = videoWidth < videoHeight ? VideoOrientation.Portrait : VideoOrientation.Landscape;
      return { width: videoWidth, height: videoHeight, orientation };
    } else {
      console.error("your browser doesn't support this API yet");
    }
  }

  async onGetDuration(videoFile: Blob) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line no-prototype-builtins
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        video.remove();
        resolve(duration);
      };

      video.src = URL.createObjectURL(videoFile);
    });
  }

  private async getVideoElement(videoFile: Blob) {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    video.src = URL.createObjectURL(videoFile);
    document.body.append(video);
    await video.play();
    return video;
  }

  private startVideosStatusListener() {
    this.interval = setInterval(() => {
      const processingVideos = (this.form.controls.videos?.value as VideoUploadEntity[])?.filter(
        ({ transcodingJobStatus }) => transcodingJobStatus !== TranscodingState.SUCCEEDED
      );

      if (processingVideos?.length > 0) {
        this.videoUploadApiService
          .getVideosStatus(processingVideos.map(({ id }) => id as string))
          .subscribe((videos) => {
            const videosMap: Map<string, TranscodingState> = new Map(
              videos.map((video) => [video.id as string, video.transcodingJobStatus])
            );
            this.form.controls.videos.setValue(
              (this.form.controls.videos?.value as VideoUploadEntity[])?.map((video) => {
                if (videosMap.has(video.id as string)) {
                  return {
                    ...video,
                    transcodingJobStatus: videosMap.get(video.id as string) as TranscodingState
                  };
                }

                return video;
              })
            );

            this.changeDetectorRef.markForCheck();
          });
      }
    }, 30000) as unknown as number;
  }

  private stopVideosStatusListener() {
    clearInterval(this.interval);
  }
}
